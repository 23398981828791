<template>
  <div>
    <a-card :title="$t('序列号复核')">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />{{ $t("返回") }}</a-button>

      <a-row gutter="12">
        <a-col :span="4" style="width: 256px; margin-bottom: 12px">
          <a-input-search v-model="filterValue" :placeholder="$t('请扫描产品条码')" />
        </a-col>
      </a-row>

      <a-spin :spinning="loading">
        <div>
          <a-table rowKey="id" size="small" :columns="curColumns" :data-source="filteredMaterialItems" :pagination="false">
            <div slot="charge_value" slot-scope="value, item">
              <!-- {{ getChargeValue(item) }} -->
              <a-input-number v-model="item.charge_value" size="small" />
            </div>
            <div slot="serial_number" slot-scope="value, item, index">
              <a-input
                v-model="item.serial_number"
                size="small"
                :focus="true"
                @pressEnter="addSerialNumber(item, index)"
              ></a-input>
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { deliveryMaterialOption } from "@/api/option";
import { deliveryRecordCreate } from "@/api/stockOut";
import NP from "number-precision";

export default {
  data() {
    return {
      description: this.$t("详情"),
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          width: 150,
          fixed: "left",
        },
        {
          title: this.$t("序列号"),
          key: "serial_number",
          scopedSlots: { customRender: "serial_number" },
        },
        {
          title: this.$t("复核数量"),
          dataIndex: "total_quantity",
          key: "total_quantity",
          width: 100,
        },
        {
          title: this.$t("计费量"),
          dataIndex: "charge_value",
          scopedSlots: { customRender: "charge_value" },
          key: "charge_value",
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: this.$t("待复核数量"),
          dataIndex: "remain_quantity",
          key: "remain_quantity",
        },
        {
          title: this.$t("批次"),
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: this.$t("计费单位"),
          dataIndex: "charge_unit_display",
          key: "charge_unit",
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      delivery_order_id: "",
      deliveryMaterialItems: [],
      filterValue: "",
    };
  },
  computed: {
    ...mapState({
      curWarehouse: (state) => state.user.currentWarehouse,
    }),
    filteredMaterialItems() {
      let filteredMaterialItems = [];
      for (let item of this.deliveryMaterialItems) {
        if (item.material_number.includes(this.filterValue)) {
          filteredMaterialItems.push(item);
        }
      }

      if (this.filterValue && this.filterValue != "" && filteredMaterialItems.length == 0) {
        this.$message.warn(`${this.$t("产品")}[${this.filterValue}]${this.$t("不存在")}`);
      }

      return filteredMaterialItems;
    },
    enableCharge() {
      return this.$store.state.user.enableCharge;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableCharge && item.key === "charge_value") {
          continue;
        }

        if (!this.enableCharge && item.key === "charge_unit") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    moment,
    initData() {
      this.delivery_order_id = this.$route.query.id;
      this.getMaterial();
    },
    getMaterial() {
      deliveryMaterialOption({
        page_size: 999999,
        delivery_order: this.$route.query.id,
        is_completed: false,
      })
        .then((data) => {
          let materialItems = data.results.map((item) => {
            return {
              ...item,
              id: item.id,
              delivery_material: item.id,
              material_name: item.material_name,
              material_number: item.material_number,
              remain_quantity: item.remain_quantity,
              total_quantity: 1,
              unit: item.unit,
              charge_unit: item.charge_unit,
              charge_value: this.getChargeValue(item),
              batch_number: item.batch_number,
              serial_number: "",
            };
          });

          this.deliveryMaterialItems = [...materialItems];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeMaterial(index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    create() {
      if (this.deliveryMaterialItems.length == 0) {
        this.$message.warn(this.$t("未添加产品"));
        return;
      }

      let formData = {
        ...this.form,
        delivery_order: this.delivery_order_id,
        delivery_record_material_items: this.deliveryMaterialItems,
      };

      this.loading = true;
      deliveryRecordCreate(formData)
        .then((data) => {
          this.$message.success(this.$t("创建成功"));
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    addSerialNumber(item, index) {
      let newItem = { ...item };
      let count = 0;
      for (let _index in this.deliveryMaterialItems) {
        if (index != _index && newItem.serial_number == this.deliveryMaterialItems[_index].serial_number) {
          this.$message.warn(this.$t("序列号重复"));
          return;
        }

        if (this.deliveryMaterialItems[_index].delivery_material == newItem.delivery_material) {
          count += 1;
        }
      }

      if (count >= newItem.remain_quantity) {
        this.$message.warn(this.$t("复核数量错误"));
        return;
      }

      newItem.serial_number = "";
      this.deliveryMaterialItems.splice(index, 0, newItem);
    },
    getChargeValue(item) {
      console.log(item);
      if (item.charge_unit === "quantity") {
        return 1;
      } else if (item.charge_unit === "ton") {
        return NP.times(1, item.weight || 0, 0.001);
      } else if (item.charge_unit === "cube") {
        return NP.times(1, item.volume || 0);
      }
      return 0;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
